@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,600,700,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.row-flex {
  display: flex;
}

.col-flex {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
}

.margin-auto {
  margin: auto;
}

.color-picker {
  width: 20px;
  height: 20px;
  border: none;
  background-color: unset;
  padding: 0;
  cursor: pointer;

  &::-webkit-color-swatch {
    border-radius: 50%;
    border: none;
  }

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
}
